import './Header.scss';

export default function Header () {
    return (
        <>
            <header className="header">
                <img src="assets/minus1by12_logo.png" className="thumbnail logo" alt="logo"/>
                <div className="logoText">
                    <div className="companyText">
                        <span>MinusOneByTwelve</span>
                        <h2>DEV</h2>
                    </div>
                    <div className="inspiringMsg rightIndent">
                        <p>ever <span className="biggerText">evolving</span>. ever <span className="biggerText">changing</span>.</p>
                        {/* <p class="rightIndent">ever <span class="biggerText">changing</span>.</p> */}
                    </div>
                </div>
            </header>
        </>
    )
}