import AboveTheFold from "./common/AboveTheFold"
import EmptyContainer from "./common/EmptyContainer"
import ProjectDetails from "./ProjectDetails";

export default function HomePage() {
    return (
        <>
            <div id="intro-particles-js"></div>
            <AboveTheFold></AboveTheFold>
            <EmptyContainer></EmptyContainer>
            <ProjectDetails></ProjectDetails>
        </> 
    )
}