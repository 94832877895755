import './ProjectCard.scss';

/* eslint-disable jsx-a11y/heading-has-content */
const ProjectCard = ({project}) => {
    return (
        <div className="card projectCard">
            <div className="card-header">
                <h6><i>Project Name</i></h6>
                <h3 dangerouslySetInnerHTML={{ __html: project.name }}></h3>
            </div>
            <div className="card-body">
                <p className="card-text" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                <div className="attributes">
                    <p>
                        <span className="label">Scope:</span>
                        <span className="value">{project.scope}</span>
                    </p>
                    <p>
                        <span className="label">Domain:</span>
                        <span className="value">{project.projectDomain}</span>
                    </p>
                    <p>
                        <span className="label">Type:</span>
                        <span className="value">{project.projectType}</span>
                    </p>
                </div>
                <div className="commitment">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="website" value="website" checked={project.commitment.website} disabled />
                        <label className="form-check-label" for="website">Website</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="logo" value="logo" checked={project.commitment.logo} disabled />
                        <label className="form-check-label" for="logo">Logo</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="Design" value="design" checked={project.commitment.design}  disabled />
                        <label className="form-check-label" for="Design">Design</label>
                    </div>
                </div>
                {/* <div className="timeline">
                    <label>Timeline</label>
                    <section className="steps">
                        <span className="step-indicators-line"></span>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.concept}" 
                                placement="bottom" ngbTooltip="Concept"></div>
                        </div>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.groundwork}"
                                placement="bottom" ngbTooltip="Groundwork"></div>
                        </div>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.planning}"
                                placement="bottom" ngbTooltip="Planning"></div>
                        </div>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.development}"
                                placement="bottom" ngbTooltip="Development"></div>
                        </div>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.testing}"
                                placement="bottom" ngbTooltip="Testing"></div>
                        </div>
                        <div className="step">
                            <div className="step-indicator" [ngClassName]="{'active' : project.timeline.launched}"
                                placement="bottom" ngbTooltip="Launched"></div>
                        </div>
                    </section>
                </div> */}
            </div>
            <div className="row card-footer text-muted">
                {project.expectedLaunch && 
                   <p className="col-md-6 col-sm-12"> 
                        <span className="label">Expected launch:</span><br/>
                        <span className="value">{project.expectedLaunch}</span>
                    </p> 
                }
                {project.initialLaunch && 
                   <p className="col-md-6 col-sm-12"> 
                        <span className="label">Released on:</span><br/>
                        <span className="value">{project.initialLaunch}</span>
                    </p> 
                }
                <div className="col-md-6 col-sm-12">
                    <p>
                        <span className="label">Stage:</span>
                        <span className="value">{project.stage}</span>
                    </p>
                    <p>
                        <span className="label">Status:</span>
                        <span className="value">{project.status}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}


export default ProjectCard;