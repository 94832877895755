import 'bootstrap/dist/css/bootstrap.css';
import "./common/styles/main.scss";

import { Route, Switch } from "react-router-dom";

import Footer from "./common/Footer"
import Header from "./common/Header"
import HomePage from "./HomePage";

const App = () => {
    return (
        <>
            <Header></Header>
            <Switch> 
                <Route path="/" exact component= {HomePage}/> 
                {/* <Route path="/courses" component= {CoursesPage}/> 
                <Route path="/about" component= {AboutPage}/> 
                <Route component= {PageNotFound}/>  */}
            </Switch> 
            <Footer></Footer>
        </>
    )
}

export default App;