const Footer = () => {
    return (
        <footer class="landingFooter">
            <div class="container">
                <div class="row minus1by12Info">
                    <div class="col-sm-12">
                        <div class="widget clearfix">
                            <img src="/assets/minus1by12_logo_small.png" alt="" class="footer-logo" />
                            <div class="info">
                                <div style={{color: '#FFF'}}>This site comes under the umbrella of <h4><a href="http://www.minus1by12.com" target="_blank" rel="noreferrer">MinusOneByTwelve Solutions LLP</a></h4></div>
                                <p><small>Empowering Individuals and Organizations with Knowledge, helping them achieve their infinite potential. Technology and humans working together in sync, each complementing the other, creating something simple and beautiful.</small></p>
                                <div class="mt-3" style={{background: "url('/assets/world-map.png') no-repeat center center", backgroundSize: '100%'}}>
                                    <address>
                                        <span style={{fontSize: '1.1rem'}}><strong>Headquarters:</strong></span><br/>
                                        JP Nagar<br/>
                                        Bengaluru - 560078, Karnataka, India<br/>
                                    </address>
                                </div>
                                <p>If you wish to talk to us, regarding partnership, consulting, training or product development, drop us a <br/> mail at <i class="far fa-envelope"></i> &nbsp;<b>contact@minus1by12.com</b> </p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </footer>
    )
}

export default Footer;