import "./ProjectDetails.scss";

import ProjectCard from "./ProjectCard";

const projects = [{
    "name": "One Pic to find them all",
    "description": "To develop and maintain, a single consolidated image of 1000+ software tools, (from multiple disciplines), that can be searched and filtered on.",
    "scope": "global",
    "projectDomain": "general help",
    "projectType" : "software",
    "commitment": {
        // "website" : "", "logo" : "", "design" : ""
    },
    "expectedLaunch": "-- / --",
    "stage": "prototype",
    "status": "Planning",
    "image": ""
}, {
    "name": "PrathamOS - <b>ADYAH</b>",
    "description": "ADYAH, 64 bit, Debain based community edition of PrathamOS, for Personal use on desktops and laptops.",
    "scope": "global",
    "projectDomain": "Operating system",
    "projectType" : "software",
    "commitment": {
        "website" : "www.prathamos.com", "logo" : "yes", "design" : "yes"
    },
    "initialLaunch" : "June, 2018",
    "stage": "production",
    "status": "Parked",
    "image": ""
}, {
    "name": "Carpanion <br/><small>( The companion car )</small>",
    "description": "This one would be from our <b>Mobility Solutions</b> division, where the team is looking to take a different approach on the Automobile ( <i>CAR</i> ), not from a safety perspective, but more on the social aspect of it.",
    "scope": "global",
    "projectDomain": "Mobility",
    "projectType" : "Hardware",
    "commitment": {
        // "website" : "", "logo" : "", "design" : ""
    },
    "expectedLaunch": "-- / --",
    "stage": "prototype",
    "status": "Design Phase",
    "image": ""
}, {
    "name": "Khana Kaisa Hai <br/><small>( How's the Food? )</small>",
    "description": "This <b>isn't</b> a guide on food preparation OR another tips and tricks blog to make better food, but is a <u><b>Rating App</b></u> to <i>rate the food that you ate ( Today ).",
    "scope": "global",
    "projectDomain": "Food",
    "projectType" : "Software",
    "commitment": {
        // "website" : "", "logo" : "", "design" : ""
    },
    "expectedLaunch": "-- / --",
    "stage": "prototype",
    "status": "Design Phase",
    "image": ""
    }, {
    "name": "99 Books",
    "description": "The <b>Literary Solutions</b> division, works with socities & apartments, to make available, <i>a light weight, resident sourced library</i>, of 99 Books; that the residents themselves take care of and read from.",
    "scope": "local",
    "projectDomain": "Books",
    "projectType" : "Hardware",
    "commitment": {
        "website" : "99books.in"
    },
    "expectedLaunch": "-- / --",
    "stage": "prototype",
    "status": "Design Phase",
    "image": ""
}];


const ProjectDetails = () => {
    return (
        <section class="projects container-fluid" id="projectsContainer">
            <h2 class="mb-4"><u>Projects & Ideas</u></h2>
            <section id="projects">
                <div class="row">
                    {projects.map(project => 
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <ProjectCard project={project} ></ProjectCard>
                        </div>
                    )}
                </div>
            </section>
        </section>
    )
}

export default ProjectDetails;