import './EmptyContainer.scss';

const EmptyContainer = () => {
    return (
        <section class=" container emptySpace">
            <h2>Inspired from <code>CLEAR</code> on Ubuntu terminal. </h2>
            <small>( this just shifts previous output upwards and if you use mouse scroll or PgUP and PgDown keys it's hard to distinguish where the output of previous command ends and output of current command begins. )</small>
        </section>
    )
}

export default EmptyContainer;