import './AboveTheFold.scss';

const AboveTheFold = () => {

    function smoothScroll(elementId) {

        let MIN_PIXELS_PER_STEP = 16;
        let MAX_SCROLL_STEPS = 30;
        let target = document.getElementById(elementId);
        let scrollContainer = target;
        do {
            scrollContainer = scrollContainer.parentNode;
    
            if (!scrollContainer) return;
            scrollContainer.scrollTop += 1;
        
        } while (scrollContainer.scrollTop === 0);
    
        var targetY = 0;
        do {
            if (target === scrollContainer) break;
            targetY += target.offsetTop;
        } while (target === target.offsetParent);
    
        var pixelsPerStep = Math.max(MIN_PIXELS_PER_STEP,
                                     (targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS);
    
        var stepFunc = function() {
            scrollContainer.scrollTop =
                Math.min(targetY, pixelsPerStep + scrollContainer.scrollTop);
    
            if (scrollContainer.scrollTop >= targetY) {
                return;
            }
    
            window.requestAnimationFrame(stepFunc);
        };
    
        window.requestAnimationFrame(stepFunc);
    }


    return (
        <main class="container main">
            <h1>Hi</h1>
            <p>This is the Parking space for all the Ideas & Projects <small>( active, inactive, protoype, in production or production ready, shelved, archived and in other states )</small> under the <br/> <span class="highlight">MINUSONEBYTWELVE SOLUTIONS</span> Umbrella.</p>
            <p>This site currently has information about <span class="highlight">19</span> of our projects. Some of these are very local to <i>Bengaluru</i>, whereas others are more global in nature. These are in different stages of their lifecycles. You can click on any project to know more.</p>
            <p class="mt-2">We are actively looking for <b>partnerships</b>, <b>collaborations</b> and <span class="highlight">Funding</span>. If you would like to connect with us on any of the projects, currently listed <b>-</b> Do let us know from within the project page.</p>

            <p class="mt-4">If you would like to know more about us, Please visit <a href="http://www.minus1by12.com" target="_blank" rel="noreferrer">MINUSONEBYTWELVE SOLUTIONS</a></p>

            <button type="button" class="button primaryBtn bigButton buttonRounded shake-horizontal" onClick={e => smoothScroll('projectsContainer')}>
                <small style={{textTransform: 'lowercase'}}>all</small> Projects
            </button>
        </main>
    )
}

export default AboveTheFold;